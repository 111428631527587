import { AuthMethod } from '@quirion/types';
import type {
  AuthChallenge,
  AuthChallengeCompat,
  AuthConfig,
  AuthNavItem,
  AuthNavSubItem,
  AuthParams,
  AuthParamsCompat,
  ParsedAuth,
} from '@quirion/types';
import { getCookie, safeJSONParse, StorageKey } from '@quirion/utils';

import { AUTH_CONFIG_BANKING } from '../../constants';

import { migrateAuthProperties } from './migrateAuthProperties';
import { migrateChallengeProperties } from './migrateChallengeProperties';
import { parseAuth } from './parseAuth';
import { parseIdToken } from './parseIdToken';
import { setCookie } from './setCookie';

const NO_VALUE = '';
const CHALLENGE_SESSION_SECONDS = 300; // TODO: session duration from BE (?)

export class AuthHelper {
  constructor(config: AuthConfig = AUTH_CONFIG_BANKING) {
    this.authKey = config?.authKey || StorageKey.Authorization;
    this.challengeKey =
      config?.challengeKey || StorageKey.AuthorizationChallenge;
    this.method = config?.method || AuthMethod.Cookie;
    this.eventName =
      config?.method === AuthMethod.Local
        ? 'storage'
        : config?.eventName || 'qauth';

    this.navigation = config?.navigation || [];
  }

  authKey: string;

  challengeKey: string;

  method: string;

  eventName: string;

  navigation: AuthNavItem[];

  /**
   * Write the current auth result to storage
   * @function
   */

  setAuth = (result: AuthParamsCompat | null = null) => {
    if (result) {
      const migratedResult = migrateAuthProperties(result);
      this.setItem(this.authKey, migratedResult);
    } else {
      this.removeItem(this.authKey);
    }
    return this.triggerEvent();
  };

  /**
   * Get the current auth challenge from storage
   * @function
   */

  getAuth = (): AuthParams | null => {
    const auth = this.getItem(this.authKey);
    if (auth && auth !== NO_VALUE) {
      return auth;
    }
    return null;
  };

  /**
   * Remove the current auth result from storage
   * @function
   */

  removeAuth = () => this.setAuth();

  /**
   * Clones and parses the base64 tokens in the auth result
   * @function
   */

  getParsedAuth = (): ParsedAuth | undefined => {
    const auth = this.getAuth();
    return parseAuth(auth);
  };

  /**
   * Extract the accessToken from the current auth result
   * @function
   */

  getAccessToken = () => {
    const auth = this.getAuth();
    return auth?.accessToken;
  };

  /**
   * Read the deviceId from storage. Generate and save one, if not yet available.
   * @function
   */

  getDeviceId = () => {
    let deviceId = this.getItem(StorageKey.DeviceId)?.deviceId;
    if (!deviceId) {
      deviceId = window?.crypto?.randomUUID?.();
      if (deviceId) {
        this.setItem(StorageKey.DeviceId, { deviceId }, 2592000); // 30 days
      }
    }
    return deviceId;
  };

  /**
   * Extract the idToken from the current auth result
   * @function
   */

  getIdToken = () => {
    const auth = this.getAuth();
    return auth?.idToken;
  };

  /**
   * Extract the refreshToken from the current auth result
   * @function
   */

  getRefreshToken = () => {
    const auth = this.getAuth();
    return auth?.refreshToken;
  };

  /**
   * Write the current auth challenge to storage
   * @function
   */

  setChallenge = (challenge: AuthChallengeCompat | null = null) => {
    if (challenge) {
      const migratedResult = migrateChallengeProperties(challenge);
      this.setItem(
        this.challengeKey,
        migratedResult,
        CHALLENGE_SESSION_SECONDS,
      );
    } else {
      this.removeItem(this.challengeKey);
    }
    return window.dispatchEvent(new Event(this.eventName));
  };

  /**
   * Get the current auth challenge from storage
   * @function
   */

  getChallenge = (): AuthChallenge | null => {
    const challenge = this.getItem(this.challengeKey);
    if (challenge && challenge !== NO_VALUE) {
      return challenge;
    }
    return null;
  };

  /**
   * Remove the current auth challenge from storage
   * @function
   */

  removeChallenge = () => this.setChallenge();

  /**
   * Checks the stored token for existence and expiry
   * @function
   */

  isAuthenticated = () => {
    const auth = this.getAuth();
    const remaining = this.getRemainingSeconds();
    return !!(auth && remaining && remaining > 0);
  };

  /**
   * Extracts the expiry time from the stored idToken
   * Returned value is counted in seconds.
   * @function
   */

  getExpiryTime = () => {
    const auth = this.getAuth();
    const idToken = parseIdToken(auth?.idToken);
    let expires = 0;
    if (auth && idToken) {
      expires = idToken[1] && idToken[1].exp;
    }
    return expires || undefined;
  };

  /**
   * Get the length of the session
   * Returned value is counted in seconds.
   * @function
   */

  getSessionSeconds = () => {
    const auth = this.getAuth();
    return auth?.expiresIn || undefined;
  };

  /**
   * Get the remaining time of the session
   * Returned value is counted in seconds.
   * @function
   */

  getRemainingSeconds = () => {
    const expires = this.getExpiryTime();
    const now = Math.floor(Date.now() / 1000);
    return expires ? expires - now : 0;
  };

  /**
   * Extracts the cognito:username from the stored idToken
   * @function
   */

  getUserName = () => {
    const auth = this.getAuth();
    const idToken = parseIdToken(auth?.idToken);
    let userName = '';
    if (auth && idToken) {
      userName = idToken[1] && idToken[1]['cognito:username'];
    }
    return userName;
  };

  /**
   * Extracts the cognito:groups from the stored idToken
   * @function
   */

  getUserGroups = () => {
    const auth = this.getAuth();
    const idToken = parseIdToken(auth?.idToken);
    let userGroups: string[] | undefined = [];
    if (auth && idToken) {
      userGroups =
        idToken[1] &&
        idToken[1]['cognito:groups']?.map(
          (group: string) => group.split('-')[0],
        );
    }
    return userGroups || [];
  };

  /**
   * Filters a navigation tree for items for who the user has the required group rights
   * Currently really specific for the admin tool.
   * See an example tree here: src/mf/admin-root-config/src/config.nav.ts
   * @function
   */

  getUserNavigation = (basePath?: string, includeHidden?: boolean) => {
    const navItems = this.navigation?.filter(
      (config) =>
        (!config.hidden || (config.hidden && includeHidden)) &&
        this.hasGroupAllowed(config.allowed) &&
        this.hasGroupsRequired(config.required),
    );

    if (navItems?.length) {
      const userNav = navItems.map((config) => ({
        ...config,
        sub: config?.sub
          ? config.sub.filter(
              (sub: AuthNavSubItem) =>
                this.hasGroupAllowed(sub.allowed) &&
                this.hasGroupsRequired(sub.required),
            )
          : undefined,
      }));
      if (basePath) {
        const userSubNav = userNav.find((n) => n.path === basePath)?.sub || [];
        return userSubNav;
      }
      return userNav;
    }
    return [];
  };

  /**
   * Checks the stored token for one or more required groups.
   * Returns true, if the user has ALL the groups.
   * @function
   */

  hasGroupsRequired = (requiredGroups?: string | string[]) => {
    if (!this.isAuthenticated()) return false;

    const userGroups = this.getUserGroups();
    if (typeof requiredGroups === 'string') {
      return userGroups.includes(requiredGroups);
    }
    if (requiredGroups && requiredGroups.length) {
      if (userGroups && userGroups.length) {
        return requiredGroups
          .map((item) => userGroups && userGroups.includes(item))
          .reduce((a, b) => a && b);
      }
      return false;
    }
    return true;
  };

  /**
   * Checks the stored token for one or more required groups.
   * Returns true, if the user has at least ONE the groups.
   * @function
   */

  hasGroupAllowed = (allowedGroups?: string | string[]) => {
    if (!this.isAuthenticated()) return false;

    const userGroups = this.getUserGroups();
    if (typeof allowedGroups === 'string') {
      return userGroups.includes(allowedGroups);
    }
    if (allowedGroups && allowedGroups.length) {
      if (userGroups && userGroups.length) {
        return allowedGroups
          .map((item) => userGroups && userGroups.includes(item))
          .reduce((a, b) => a || b);
      }
      return false;
    }
    return true;
  };

  /**
   * Checks with auth and nav info, if user has access to the path.
   * Currently works only with two levels of depth: main and sub.
   * @function
   */

  hasAccess = (path: string) => {
    const fragments = path.split('/').filter((v) => v);

    let userNavigation = this.getUserNavigation(undefined, true);
    let fragment = fragments[0];
    const mainLevel = userNavigation.find(
      (nav: AuthNavItem) => nav.path?.substring(1) === fragment,
    );
    if (!mainLevel) return false;
    if (fragments.length === 1) return true;

    userNavigation = this.getUserNavigation(`/${fragment}`, true);
    // eslint-disable-next-line prefer-destructuring
    fragment = fragments[1];
    if (fragment?.indexOf(':') === 0 && fragments[2]) {
      // If fragment is variable and there is another fragment in the path, add this, too..
      // So you have `:externalId/person` instead of just `:externalId`. See `config.nav.ts`.
      fragment += `/${fragments[2]}`;
    }

    const subLevel = userNavigation.find(
      (nav: AuthNavItem) => nav.path?.substring(1) === fragment,
    );
    if (!subLevel) return false;

    return true;
  };

  /**
   * Internal function used to abstract storing items
   * @function
   * @param expiresIn in Seconds
   */

  setItem(key: string, value: any, expiresIn?: number) {
    const stringified = JSON.stringify(value);
    if (this.method === AuthMethod.Local) {
      window.localStorage.setItem(key, stringified);
    } else {
      const maxAge = value?.expiresIn || expiresIn;
      const { accessToken, idToken, refreshToken } = value;
      if (accessToken || idToken || refreshToken) {
        const storageValue = { ...value };

        setCookie(`${key}_access`, JSON.stringify(accessToken), maxAge);
        delete storageValue.accessToken;
        setCookie(`${key}_id`, JSON.stringify(idToken), maxAge);
        delete storageValue.idToken;
        setCookie(`${key}_refresh`, JSON.stringify(refreshToken), maxAge);
        delete storageValue.refreshToken;

        setCookie(key, JSON.stringify(storageValue), maxAge);
        return;
      }
      setCookie(key, stringified, maxAge);
    }
  }

  /**
   * Internal function used to abstract getting items from storage
   * @function
   */

  getItem(key: string) {
    if (this.method === AuthMethod.Local) {
      return JSON.parse(window.localStorage.getItem(key) || '{}');
    }

    const value = getCookie(key);
    if (value) {
      const parsed = migrateAuthProperties(safeJSONParse(value));

      if (!parsed.accessToken && document.cookie.includes(`${key}_access=`))
        parsed.accessToken = safeJSONParse(getCookie(`${key}_access`));

      if (!parsed.idToken && document.cookie.includes(`${key}_id=`))
        parsed.idToken = safeJSONParse(getCookie(`${key}_id`));

      if (
        !parsed.refreshToken &&
        document.cookie.includes(`${key}_refresh=`) &&
        getCookie(`${key}_refresh`) // may be missing when signed in with amplify -> important during migration
      )
        parsed.refreshToken = JSON.parse(getCookie(`${key}_refresh`));

      return parsed;
    }

    return value;
  }

  /**
   * Internal function used to abstract removing items from storage
   * @function
   */

  removeItem(key: string) {
    if (this.method === AuthMethod.Local) {
      window.localStorage.removeItem(key);
    } else {
      if (document.cookie.includes(`${key}=`)) setCookie(key, NO_VALUE, 1);
      if (document.cookie.includes(`${key}_access=`))
        setCookie(`${key}_access`, NO_VALUE, 1);
      if (document.cookie.includes(`${key}_id=`))
        setCookie(`${key}_id`, NO_VALUE, 1);
      if (document.cookie.includes(`${key}_refresh=`))
        setCookie(`${key}_refresh`, NO_VALUE, 1);
    }
  }

  /**
   * Subscribe to authentication events
   * @function
   */

  subscribe(func: () => void) {
    return window.addEventListener(this.eventName, func);
  }

  /**
   * Unsubscribe from authentication events
   * @function
   */

  unsubscribe(func: () => void) {
    return window.removeEventListener(this.eventName, func);
  }

  /**
   * Trigger an authentication event
   * @function
   */

  triggerEvent() {
    return window.dispatchEvent(new Event(this.eventName));
  }
}
